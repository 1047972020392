html {
    position: relative;
    min-height: 100%;
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: white;
    color: #615751;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
button {
    /*font-family: 'FuturaPT';*/
}

input::placeholder,
textarea::placeholder {
    color: #a4a4a4;
    font-size: 0.95em;
}

a {
    color: #615751;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.embed-container-home {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container-home iframe,
.embed-container-home object,
.embed-container-home embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/*
*
* ANIMATIONS
*
*/

@-webkit-keyframes loading {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes loading {
    to {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes loading {
    to {
        -ms-transform: rotate(360deg);
    }
}

@keyframes loading {
    to {
        transform: rotate(360deg);
    }
}

.fade-enter {
    opacity: 0;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-exit,
.fade-exit.fade-exit-active {
    display: none;
}
.ReactTable .rt-th, .ReactTable .rt-td { overflow: unset;text-overflow: unset; }
.ReactTable .rt-thead .rt-resizable-header-content { overflow: unset;text-overflow: unset;white-space: normal; }