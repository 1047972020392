.PowerSelect {
    position: relative;
    display: block;
    width: 100%;
    padding: 2px 3px;
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    color: rgb(94, 94, 94);
    border-radius: 5px;
    border: 1px solid rgb(229, 229, 229);
    transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
    cursor: pointer;
}

.PowerSelect:hover {
    border: 1px solid #E50043;
    box-shadow: 0 0 0 1px #E50043 inset;
}

.PowerSelect.white-border {
    border: 1px solid rgb(255, 255, 255);
}

.PowerSelect:focus {
    outline: none
}

.PowerSelect--focused {}

.PowerSelect--disabled {
    background-color: #eee;
    cursor: not-allowed
}

.PowerSelect--disabled .PowerSelect__Trigger {
    pointer-events: none
}

.PowerSelect--disabled .PowerSelect__TriggerInput {
    background-color: #eee
}

.PowerSelect--open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0
}

.PowerSelect--open .PowerSelect__TriggerStatus:before {
    transform: rotate(-180deg)
}

.PowerSelect__Trigger {
    position: relative;
    height: 34px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.PowerSelect__Menu {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.PowerSelect__Menu.white-border__Menu {
    border: none;
    border-top: 1px solid #a0a0a0;
}

.PowerSelect__Menu:focus {
    outline: none
}

.PowerSelect__Options {
    position: relative;
    max-height: 238px;
    overflow: auto
}

.PowerSelect__OptGroup {
    padding-left: 8px
}

.PowerSelect__OptGroup__Label {
    font-weight: 700;
    font-size: .9em;
    color: #666;
    padding: 8px 0 4px
}

.PowerSelect__Option {
    cursor: pointer;
    padding: 8px 12px
}

.PowerSelect__Option:not(.PowerSelect__Option--disabled):hover {
    background-color: rgba(227, 0, 106, 0.5);
}

.PowerSelect__Option--disabled {
    color: #999;
    cursor: not-allowed
}

.PowerSelect__Option--highlighted {
    background-color: #f1f1f1
}

.PowerSelect__BeforeOptions {
    padding: 8px 12px
}

.PowerSelect__Placeholder {
    color: #ccc;
    font-size: 14px
}

.PowerSelect__SearchInputContainer {
    padding: 8px
}

.PowerSelect__SearchInput {
    display: block;
    width: 100%;
    height: 34px;
    padding: 4px 8px;
    line-height: 1.4;
    font-size: inherit;
    border: 1px solid #ccc;
    border-radius: 2px;
    cursor: pointer
}

.PowerSelect__SearchInput:focus {
    border-color: #66afe9;
    outline: none
}

.PowerSelect__TriggerInput {
    display: inline-block;
    width: 100%;
    height: 34px;
    border: none;
    cursor: pointer;
    padding: 4px 22px 4px 8px;
    font-size: inherit;
}

.PowerSelect__TriggerInput:focus {
    outline: none
}

.PowerSelect__Trigger__LHS,
.PowerSelect__Trigger__RHS {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap
}

.PowerSelect__Trigger__LHS {
    padding-left: 8px;
    padding-right: 4px
}

.PowerSelect__Trigger__RHS {
    padding-right: 4px;
    padding-left: 4px
}

.PowerSelect__TriggerLabel {
    padding: 6px 22px 6px 8px
}

.PowerSelect__Trigger__LHS+.PowerSelect__TriggerLabel {
    padding-left: 4px
}

.PowerSelect__TriggerStatus {
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 4px
}

.PowerSelect__TriggerStatus:before {
    content: "";
    display: block;
    border-top: 4px solid #E50043;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent
}

.PowerSelect__Clear {
    padding: 4px;
    color: #888
}

.PowerSelect__Clear:hover {
    color: #333
}

.PowerSelect__Clear:before {
    content: "\D7"
}

.PowerSelect__Trigger--empty .PowerSelect__Clear {
    visibility: hidden
}

.PowerSelect__Clear,
.PowerSelect__TriggerInputContainer,
.PowerSelect__TriggerLabel,
.PowerSelect__TriggerStatus,
.PowerSelectMultiple__OptionsContainer {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    height: 34px
}

.PowerSelectMultiple__OptionsContainer {
    padding-top: 2px;
    padding-bottom: 2px
}

.PowerSelectMultiple__SelectedOptions {
    list-style: none;
    padding-left: 0;
    display: block
}

.PowerSelectMultiple__SelectedOptions:after {
    content: "";
    display: table;
    clear: both
}

.PowerSelectMultiple__SelectedOptions>li {
    display: block;
    float: left;
    margin-top: 2px;
    margin-bottom: 2px
}

.PowerSelect__Trigger--empty .PowerSelectMultiple_TriggerInputContainer {
    float: none;
    margin-left: 4px
}

.PowerSelectMultiple__SelectedOption {
    line-height: 24px;
    margin-left: 4px;
    background-color: #ebeeff;
    border: 1px solid #c6cfff
}

.PowerSelectMultiple__SelectedOption__Close,
.PowerSelectMultiple__SelectedOption__Label {
    padding: 0 6px;
    display: block;
    float: left
}

.PowerSelectMultiple__SelectedOption__Close {
    border-left: 1px solid #c6cfff;
    cursor: pointer
}

.PowerSelectMultiple__SelectedOption__Close:hover {
    background-color: #dce1ff
}

.PowerSelectMultiple .PowerSelect__Trigger {
    white-space: normal;
    height: auto
}

.PowerSelectMultiple .PowerSelect__TriggerInput {
    display: inline-block;
    width: auto;
    padding: 0;
    height: 26px;
    margin-left: 4px
}

.PowerSelectMultiple .PowerSelect__Trigger--empty .PowerSelect__TriggerInput {
    width: 100%
}

.tether-element {
    z-index: 1000;
}