/*
*
*
* REACT-TABLE-STYLES
*
*/

.ReactTable {
	border-radius: 5px;
	overflow: hidden;
}

.ReactTable .rt-thead.-header {
	background-color: #E50043;
}
.ReactTable .rt-thead .rt-tr {
	text-align: left;
}
.ReactTable .rt-thead.-header .rt-th {
	color: white;
	padding: 10px 5px;
	font-weight: 500;
	font-size: 16px;
}
@media (max-width: 1000px) {
	.ReactTable .rt-thead.-header .rt-th {
		font-size: 14px;
		padding: 8px;
	}
}
@media (max-width: 800px) {
	.ReactTable .rt-thead.-header .rt-th {
		font-size: 13px;
		padding: 6px 8px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-thead.-header .rt-th {
		font-size: 12px;
		padding: 5px 8px;
	}
}

.ReactTable .rt-thead.-filter .rt-th {
	color: white;
	padding: 10px 5px;
}
@media (max-width: 800px) {
	.ReactTable .rt-thead.-filter .rt-th {
		padding: 8px 5px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-thead.-filter .rt-th {
		padding: 5px;
	}
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
	-webkit-box-shadow: inset 0 3px 0 0 white;
	box-shadow: inset 0 3px 0 0 white;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
	-webkit-box-shadow: inset 0 -3px 0 0 white;
	box-shadow: inset 0 -3px 0 0 white;
}

.ReactTable .rt-thead .rt-th input[type='text'] {
	background-image: url(./images/searchIcon.png);
	background-position: calc(100% - 3px) center;
	background-repeat: no-repeat;
	background-size: auto calc(100% - 6px);
	padding: 5px;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	font-size: 16px;

	transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
}
@media (max-width: 1000px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 14px;
	}
}
@media (max-width: 800px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 14px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 12px;
	}
}

.ReactTable .rt-thead .rt-th input[type='text']:hover {
	border-color: #E50043;
	box-shadow: 0 0 0 1px #E50043 inset;
}

.ReactTable .rt-tbody .rt-td {
	align-self: center;
	padding: 10px 5px;
	font-size: 14px;
}
@media (max-width: 800px) {
	.ReactTable .rt-tbody .rt-td {
		padding: 8px 5px;
		font-size: 13px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-tbody .rt-td {
		padding: 5px;
		font-size: 12px;
	}
}

.ReactTable .rt-tbody .rt-td.actionCell {
	padding: 5px;
}
.ReactTable .rt-tbody .rt-td.noPadding {
	padding: 0px;
}
.ReactTable .rt-tbody .rt-td.center {
	text-align: center;
}
.ReactTable .rt-tbody .rt-td.capitalize {
	text-transform: capitalize;
}
.ReactTable .rt-tbody .rt-td.center-capitalize {
	text-align: center;
	text-transform: capitalize;
}
.ReactTable .rt-tbody .rt-td.right {
	text-align: right;
}
.ReactTable .rt-tbody .rt-td.pre-line {
	white-space: pre-line;
	line-height: 1.25;
}
