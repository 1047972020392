.u-position-absolute {
	position: absolute;
}

.u-position-relative {
	position: relative;
}

.accordion {
}

.accordion__item + .accordion__item {
}

.accordion__item--has-icon {
	position: relative;
}

.accordion__title {
	position: relative;
	cursor: pointer;
	width: 100%;
	text-align: left;
	border: none;
	outline: none;
}

.accordion__title:hover {
}

.accordion__body {
	display: block;
	animation: fadein 0.35s ease-in;
}

.accordion__body--hidden {
	display: none;
	opacity: 0;
	animation: fadein 0.35s ease-in;
}

.accordion__title > *:last-child,
.accordion__body > *:last-child {
	margin-bottom: 0;
}

.accordion__arrow {
	width: 30px;
	height: 12px;
	position: absolute;
	top: 50%;
	right: 10px;
	margin-top: -6px;
}

.accordion__arrow::after,
.accordion__arrow::before {
	display: block;
	position: absolute;
	top: 50%;
	border-radius: 2px;
	width: 15px;
	height: 3px;
	background-color: currentColor;
	content: '';
}

.accordion__arrow::before {
	left: 3px;
	transform: rotate(45deg);
}

[aria-selected='true'] .accordion__arrow::before,
[aria-expanded='true'] .accordion__arrow::before {
	transform: rotate(-45deg);
}

.accordion__arrow::after {
	right: 3px;
	transform: rotate(-45deg);
}

[aria-selected='true'] .accordion__arrow::after,
[aria-expanded='true'] .accordion__arrow::after {
	transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
	transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}
/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes move-down {
	0% {
		transform: translateY(0);
	}
	10% {
		transform: translateY(0);
	}
	20% {
		transform: translateY(5px);
	}
	30% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes move-up {
	0% {
		transform: translateY(0);
	}
	10% {
		transform: translateY(0);
	}
	20% {
		transform: translateY(-5px);
	}
	30% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}
